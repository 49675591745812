import React from 'react'
import { Link } from 'gatsby'
import { Flex, Button, Heading, Text } from 'theme-ui'
import { FaRegHandshake } from 'react-icons/fa'
import styled from 'styled-components'

const styles = {
  wrapper: {
    alignItems: `left`,
    flexDirection: `column`,
    bg: `omegaLighter`,
    borderRadius: `lg`,
    size: `full`,
    p: 4
  },
  heading: {
    color: `omegaDark`,
    svg: {
      color: `beta`,
      size: `icon.lg`,
      display: `block`,
      mb: 3
    }
  },
  subheading: {
    color: `omegaDark`,
    fontWeight: `normal`
  },
  list: {
    color: `omegaDark`,
    listStyle: `none`,
    m: 0,
    p: 0,
    mb: 3,
    li: {
      p: 0,
      my: 2
    },
    'li:before': {
      content: `""`,
      display: `inline-block`,
      width: `icon.xs`,
      bg: `success`,
      borderRadius: `full`,
      size: `7px`,
      mr: `7px`
    }
  },
  primaryButton: {
    display: `block`,
    mb: `1rem`
  },
  secondaryButton: {
    display: `block`,
    mb: `1rem`,
    backgroundColor: `grey`,
    borderWidth: `0`
  }
}

const BannerVertical = () => (
  <Flex sx={styles.wrapper}>
    <Heading variant='h2' sx={styles.heading}>
      <FaRegHandshake />
      Join the buildingSMART Network
    </Heading>
    <Text as='ul' variant='small' sx={styles.list}>
      <li>Latest updates from the built-environment sector</li>
      <li>Exclusive access to networking events</li>
      <li>Access to technical talents in the sector</li>
    </Text>
    <Button
      variant='primary'
      as={Link}
      to='/app/profile'
      sx={styles.primaryButton}
      aria-label='Learn More'
    >
      Registration
    </Button>
    <Button
      variant='primary'
      as={Link}
      to='/app/assessment'
      sx={styles.secondaryButton}
      aria-label='Learn More'
    >
      Check Eligibility
    </Button>
    <Mini>
      * By providing your personal information, you agree to receive exclusive
      updates from buildingSMART
    </Mini>
  </Flex>
)

export default BannerVertical

const Mini = styled.p`
  font-size: 0.8em;
  color: gray;
  margin: 0.4em 0 0 0;
`
